.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Ysabeau+Infant:wght@1&display=swap');

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html, body {margin: 0; height: 100%; overflow: hidden}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  background-color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  width:100vw;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper {
  background-color: #000000;
  width:100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page {
  height: 580px;
  object-fit: cover;
  display: block;
}

.logo {
  filter: invert(1);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 760px;
  text-align: center;
  font-size: 60px;
  font-family: 'Ysabeau Infant', sans-serif;
}

.logo-img {
  width: 400px;
}

.button {
  filter: invert(1);
  width: 50px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 360px;
  right: 0;
  top: 600px;
  text-align: center;
}

.button-left {
  filter: invert(1);
  width: 50px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 360px;
  top: 600px;
  text-align: center;
  transform: scaleX(-1)
}